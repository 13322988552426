.divItemContainer {
	max-width: 250px;
}
.imgItem {
    width: 115px;
    height: 115px;
    margin: 0 auto 20px auto;
    position: relative;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: 50%;
}
.tltItem {
    text-align: center;
    font-size: 19px;
    font-family: saira;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}
.pItem {
    text-align: center;
    color: #333333;
    font-family: muli;
    font-size: 16px;
    line-height: 21px;
}

.pItem p {
    margin-bottom: 2.5px;
}

.divFatherContainerItem {
	justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
    padding-top:35px; 
}