#segundaSeccion {
    background: url('../../assets/images/Reticula-1.svg');
    background-size: cover;
    background-repeat: repeat;
    padding-top: 5rem;
    padding-bottom: 5rem;
}
@media (max-width:900px){
    #segundaSeccion {
        background-size: 100% auto !important   ;
    }
} 