.divItemContainer {
	max-width: 250px;
}
.pDiv{
	display: flex;
	align-items: center;
	flex-flow: column;
	padding: 0px 10px;
}

.label{
	border-bottom: solid 2px;
    position: relative;
    left: 0;
	margin: 53px 0 20px 0;
}
#secPortafolio .divFatherContainerItem div{
	padding: 0
}
.cont-imgP{
    width: 180px;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.imgP{
	width: 150px;
    height: 150px;
    margin: 0 auto 40px auto;
    border-radius: 50%;
    -webkit-transition: width 0.5s,height 0.5s ; /* For Safari 3.1 to 6.0 */
    transition: width 0.5s,height 0.5s ;
    
    
}
.titP {
    text-align: center;
    font-size: 20px;
    font-family: saira;
    letter-spacing: -2px;
    margin: 1rem 0
}
.titP2 {
    text-align: center;
    font-size: 20px;
    font-family: saira;
    font-weight: 100;
}

.label::after {
    content: "\2022";
    position: absolute;
    font-size: 50px;
    top: -1.5rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    font-family: sans-serif;
}
.label::before {
    content: " ";
    background: black;
    width: 1px;
    height: 50px;
    position: absolute;
    top: -2.5rem;
    font-size: 36px;
    font-weight: 100;
    left: 50%;
    transform: translateX(-50%);
}

.parP {
    text-align: justify;
    font-size: 16px;
    color: #333333;
    padding: 0 20px;
    font-family: muli;
    font-size: 16px;
    line-height: 24px;
}

.linkP {
		width: 26px;
		cursor: pointer;
	
}
@media (max-width:768px){
   .pDiv{
    margin-top: 20px;
   }
   
}
@media (max-width:360px){
    
    .label:before {
     top: -2.9rem;
 }
 }