.newsletter-form .form-group {
    justify-content: center;
    height: 45px;
}
.newsletter-form {
    max-width: 1000px;
    margin: 0 auto;
}
.newsletter-form .form-group div button {
    width: 100%;
    background: transparent;
    color: black;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    height: 100%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 0px;
    cursor: pointer;
    font-family: saira;
    font-weight: 600;
}
.newsletter-form .form-group div button:hover {
    color: black;
    background-color: white;
    text-shadow: 0 0 10px rgba(150, 150, 150, 1);
}
.form-group div input {
	border-bottom: 1px solid !important;
    border: none;
    border-radius: inherit;
    height: calc(2em + .75rem + 2px) !important;
}
.form-group div input:focus {
    border-color: unset;
    box-shadow: unset;
}
.form-group div input::placeholder {
    font-size: 2.5vw;
	text-align: center;
	font-family: saira;
}
@media (min-width: 1600px) {
    .form-group div input::placeholder {
        font-size: 40px;
    }
}
@media (max-width:767px){
    .form-group div.col-5{
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .form-group div input {
        text-align: center;
    } 
    #Newsletter{
        padding-bottom: 60px !important;
    }
    .newsletter-form .form-group div button {
        font-size: 20px;
    }
    .newsletter-form .form-group {
        height: 90px;
    }
}
@media(max-width: 575px){
    input.form-control::placeholder {
        font-size: 18px !important ;
    }
    .tltNewsletter h2 {
        font-size: 35px;
    }
}
@media (max-width:360px){
    input.form-control::placeholder {
        font-size: 16px !important;
    }
    .newsletter-form .form-group div button {
        
        font-size: 20px;
        
    }
}