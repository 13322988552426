.loadingPortafolio {
    position: absolute;
    background:radial-gradient(circle at 50% 50%, gray -101%, black 32%);
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0;
    transition: all 500ms;
    z-index: 1;
    pointer-events: none;
}

.loadingPortafolio.loadingVisible {
    opacity: 1;
}

@media (max-width: 619px) {

  .menu-social-container-share, .portfolio-link {
    display: none !important;
  }

  .categoriesPortafolio li {
    font-size: 16.5px;
    margin-bottom: 10px;
  }

  .bottom-options li.menu-social-container {
    margin: 0 auto;
  }

}

  .containerLoader{
    position:absolute;
    height:100px;
    width:100px;
    left: 50%;
    top:50%;
    padding: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    -webkit-perspective:400px;
            perspective:400px;
  }
  .cube{
    height:100px;
    width:100px;
    -webkit-transform-origin:50% 50%;
            transform-origin:50% 50%;
    -webkit-transform-style:preserve-3d;
            transform-style:preserve-3d;
    -webkit-animation:rotate 4s infinite ease-in-out;
            animation:rotate 4s infinite ease-in-out;
    
  }
  .side{
    position:absolute;
    display:block;
    height:100px;
    width:100px;
    background: transparent !important;
    border: 2px solid #464646;
  }
  .side1{
    background:#41C3AC;
    -webkit-transform:translateZ(50px);
            transform:translateZ(50px);
  }
  .side2{
    background:#FF884D;
    -webkit-transform:rotateY(90deg) translateZ(50px);
            transform:rotateY(90deg) translateZ(50px);
  }
  .side3{
    background:#32526E;
    -webkit-transform:rotateY(180deg) translateZ(50px);
            transform:rotateY(180deg) translateZ(50px);
  }
  .side4{
    background:#65A2C5;
    -webkit-transform:rotateY(-90deg) translateZ(50px);
            transform:rotateY(-90deg) translateZ(50px);
  }
  .side5{
    background:#FFCC5C;
    -webkit-transform:rotateX(90deg) translateZ(50px);
            transform:rotateX(90deg) translateZ(50px);
  }
  .side6{
    background:#FF6B57;
    -webkit-transform:rotateX(-90deg) translateZ(50px);
            transform:rotateX(-90deg) translateZ(50px);
  }
  
  @-webkit-keyframes rotate{
    0%{
      -webkit-transform:rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    33.33%{
       -webkit-transform:rotateX(360deg) rotateY(0deg)  rotateZ(0deg);    
    }
    66.66%{
       -webkit-transform:rotateX(360deg) rotateY(360deg)  rotateZ(0deg);
    }
    100%{
       -webkit-transform:rotateX(360deg) rotateY(360deg)  rotateZ(360deg);
    }
  }
  @keyframes rotate{
    0%{
      transform:rotateX(0deg) rotateY(0deg);
    }
    50%{
      transform:rotateX(360deg) rotateY(0deg);    
    }
    100%{
      transform:rotateX(360deg) rotateY(360deg);
    }
  }