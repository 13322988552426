.buttonMorePortfolio {
    width: fit-content;
    margin: 0 auto;
    margin-top: 1rem;
    background: transparent;
    color: black;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    height: 100%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 0px;
    cursor: pointer;
    font-family: saira;
    font-weight: 600;
}
.buttonMorePortfolio:hover {
    color: black;
    background-color: white;
    text-shadow: 0 0 10px rgba(150, 150, 150, 1);
}