.imgC {
    width: 100%;
}
.imgN{
    height: 10vw;
    margin: 0 auto;
    position: relative;
    background-size: cover !important;
    background-position: center !important;
}
.parN {
    font-size: 16px;
    line-height: 24px;
    font-family: saira;
    color: #333;
    text-align: left;
    padding-top: 10px;
}
.divLinkBlogContainer {
    text-align: center;
    padding-top: 35px;
}
#noticias .divFatherContainerItem {
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;
    padding-top: 33px;
}
.divLinkBlogContainer .aLinkBlogContainer {
    text-transform: uppercase;
    font-size: 26px;
    position: relative;
}
.divLinkBlogContainer .aLinkBlogContainer:after {
    content: "";
    width: calc(56% * 2);
    background: #333333;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-4.5%);
}
@media (max-width: 991px){
    .imgN{
        height: 20vw;
    }
}

@media (max-width:768px){
    #noticias .divFatherContainerItem {
        width: 100%;
    }

    .divLinkBlogContainer .aLinkBlogContainer {
        text-transform: uppercase;
        font-size: 22px;
        position: relative;
    }
}

@media (max-width:360px){
    .divLinkBlogContainer .aLinkBlogContainer {
        font-size: 22px;
    }
}