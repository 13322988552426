.awards-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    padding-left: calc(44% - 200px);
}

.container-award {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.text-award {
    font-size: 20px;
    font-family: saira;
    border-bottom: 1px solid black;
    padding-bottom: 12px;
    font-weight: bold;
    width: 100%;
    text-align: right;
    margin-bottom: -20px;
}

.image-award {
    width: 115px;
    margin-bottom: 42px;
}

.awards-item p {
    margin-bottom: 0;
    color: #333333;
    font-family: muli;
    font-size: 16px;
}

@media only screen and (max-width: 899px) {
    .awards-item p {
        margin-bottom: 15px;
    }
  }

  @media only screen and (max-width: 1199) {
    .awards-item {
        justify-content: center !important;
    }
  }