/* ================================================== */
/* ================ ESTILOS GENERALES =============== */
/* ================================================== */

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'muli';
}

a, a:hover {
  text-decoration: none;
  color: unset;
}

button:active, button:focus {
  color: unset !important;
  background-color: unset !important;
  border-color: unset !important;
  outline: none !important;
  box-shadow: unset !important;
}

/* TEXT */
.color-white {
  color: white;
}
.color-dark-gray {
  color: #333333;
}
.color-light-gray {
  color: #4D4D4D;
}
.color-black {
  color: black;
}
/* END TEXT */

/* LINKS */
.link, .link:hover {
  text-decoration: none;
  color: unset;
}
/* END LINKS */

/* HEIGHT */
.full-height {
  height: 100%;
}
/* END HEIGHT */
/*PADDING 100 FOR CONTAINER FLUID*/
.p-100 {
  padding-left: 20% !important;
  padding-right: 20% !important;
  max-width: 2560px;
}
/*PADDING 100 FOR CONTAINER FLUID*/
/*FONT FAMILY*/
@font-face {
  font-family: saira;
  src: url(./assets/fonts/Saira-Regular.ttf);
}
@font-face {
  font-family: sairaL;
  src: url(./assets/fonts/Saira-Light.ttf);
}
@font-face {
  font-family: muli;
  src: url(./assets/fonts/Muli-Regular.ttf);
}

/*FONT FAMILY*/