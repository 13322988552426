footer {
    background: url('../../assets/images/footer-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 8rem 36px;
    display: flex;
    align-items: center;
    width: 100%;
}
@media(max-width: 575px ){
    footer {
        height: auto;
    } 
    footer .country-container h3 {
        font-size: 23px;
    }
    footer .country-container p {
        font-size: 16px;
    }
}
.row-text {
    display: flex;
    justify-content: center;
}
footer .footer-description-container,
footer .footer-email-container {
    text-align: center;
}
footer .footer-email-container h1 {
    color: white;
    font-size: 65px;
    font-size: 55px;
    font-family: saira;
}
footer .footer-email-container {
    margin-bottom: 26px;
}
footer .footer-description-container {
    margin-bottom: 56px;
}
footer .footer-description-container p {
    margin: 0;
    font-family: muli;
    font-size: 20px;
}
footer .footer-email-container .email-footer {
    font-size: 55px;
    font-family: saira;
}
.input-blog {
    background: transparent !important;
    color: white !important;
}
.p-blog p {
    margin: 0;
    text-align: center;
}
.input-blog::placeholder {
    color: white !important;
}
.button-blog {
    color: white !important;
}
footer .footer-email-container .email-footer:hover {
    color: white;
}
footer .country-container {
    text-align: center;
    margin-bottom: 25px;
}
footer .country-container h3 {
    margin-top: 10px;
    font-family: muli;
}
footer .country-container p {
    margin-bottom: 0;
    font-family: muli;
}
footer .contact-link-container {
    text-align: center;
    margin-bottom: 25px;
    font-family: saira;
    padding-top: 72px;
}
footer .contact-link-container a {
    text-transform: uppercase;
    font-size: 26px;
    position: relative;
}
footer .contact-link-container a:after {
    content: "";
    width: calc(56% * 2);
    background: white;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-4.5%);
}
footer .contact-link-container a:hover {
    color: white;
}
footer .app-container {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}
footer .app-container a img {
    height: 60px;
}
footer .social-container {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
    margin: 0 auto;
}
.form-blog {
    margin: 3rem 0rem;
}
footer .social-container a img {
    height: 42px;
}
@media(max-width: 991px){
    footer .footer-email-container h1 {
        font-size: 50px;
    }

}
@media (max-width:767px){
    footer .footer-email-container .email-footer {
        font-size: 7vw;
    }
    .p-blog p {
        font-size: 15px;
    }
    footer .footer-email-container h1 {
        font-size: 40px;
    }
}
@media(max-width: 575px){
    .foo-blog {
        height: auto;
    }   
}