.imgBlog {
    width: 100%;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    height: 12.5vw;  
}
.item-blog {
    cursor: pointer;
}
.space-blog {
	padding-top: 75px !important;
    padding-bottom: 75px !important;
    padding: 19%;
    background: url('../../assets/images/Reticula-1.svg');
    background-size: 100% auto !important;
    background-repeat: repeat;
}
.pBlog {
    font-family: muli;
    font-size: 15px;
    margin-bottom: 3rem;
}
.dateBlog {
    text-align: right;
	margin: 0;
    font-family: muli;	    
    padding-bottom: 5px;
    font-size: 14px;
}
.tltBlog {
	font-family: saira;
    font-weight: 600;
    font-size: 21px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.linkBlog,.linkBlog:hover{
    color: #2d2d2d;
    text-decoration: none;
}
@media (max-width: 991px){
    .space-blog {
        padding: 18%;
    }
}
@media (max-width: 767px){
    .space-blog {
        padding: 21%;
    }
    .imgBlog {
        height: 30vw;
    }
}
@media (max-width: 575px){
    .space-blog {
        padding: 18vw;
    }
}

