footer {
    background: url('../../assets/images/footer-background.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 8rem 36px;
    display: flex;
    align-items: center;
}
@media(max-width: 575px ){
    footer {
        height: auto;
    } 
    footer .country-container h3 {
        font-size: 23px;
    }
    footer .country-container p {
        font-size: 16px;
    }
}
.row-text {
    display: flex;
    justify-content: center;
}
footer .footer-description-container,
footer .footer-email-container {
    text-align: center;
}
footer .footer-email-container {
    margin-bottom: 26px;
}
footer .footer-description-container {
    margin-bottom: 56px;
}
footer .footer-description-container p {
    margin: 0;
    font-family: muli;
    font-size: 20px;
}
footer .footer-email-container .email-footer {
    font-size: 55px;
    font-family: saira;
}
footer .footer-email-container .email-footer:hover {
    color: white;
}
footer .country-container {
    text-align: center;
    margin-bottom: 25px;
}
footer .country-container h3 {
    margin-top: 10px;
    font-family: muli;
}
footer .country-container p {
    margin-bottom: 0;
    font-family: muli;
}
footer .contact-link-container {
    text-align: center;
    margin-bottom: 25px;
    font-family: saira;
    padding-top: 72px;
}
footer .contact-link-container a {
    text-transform: uppercase;
    font-size: 26px;
    position: relative;
}
footer .contact-link-container a:after {
    content: "";
    width: calc(56% * 2);
    background: white;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-4.5%);
}
footer .contact-link-container a:hover {
    color: white;
}
footer .app-container {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
}
footer .app-container a img {
    height: 60px;
}
footer .social-container {
    display: flex;
    justify-content: space-evenly;
    width: 30%;
    margin: 0 auto;
}
footer .social-container a img {
    height: 42px;
}
@media (max-width:768px){
    footer .footer-email-container .email-footer {
        font-size: 7vw;
        
    }
}
#message {
    height: 76px !important;
}

.form-control-footer {
    background: transparent;
    border-radius: 0;
    color: white;
    border: 0px;
    border-bottom: 1px solid white;
}
.form-control-footer:focus {
    color: white;
    background-color: transparent;
    border-color: white;
    box-shadow: none;
    outline: 0;
    border: 0px;
    border-bottom: 2px solid white;
}
.error-message {
    font-size: 13px;
    display: block;
    padding-top: 4px;
    height: 18px;
    transition: all 250ms;
    color: #d4d4d4;
}
.lds-ellipsis {
    display: inline-block;
    position: relative;
    margin-top: -10px;
    width: 80px;
    height: 36px;
    margin-bottom: 15px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  #contact-form {
    opacity: 1;
  }

  .success-form {
    opacity: 0 !important;
    height: 0px;
}