#featured-about {
    background: radial-gradient(circle at 50% 50%, gray -101%, black 32%);
    padding-bottom: 8%;
    padding-top: 8%;
    height: 100vh;
    display: flex;
    align-items: center;
}
.header-about {
    height: 100vh;
    transition: all 2s;
}

.contract {
    height: 328px !important;
}
.header-title-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.title {
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    font-size: 56px;
    font-family: saira;
    margin: 0;
    position: relative;
    padding-bottom: 15px;
    min-height: 82px;
    transition: opacity 2s;
    opacity: 0
}
.loaded {
    opacity: 1 !important;
    width: 500px !important;
}
@media(max-width: 991px) {
    .loaded {
        width: 100% !important;
    }
}
.title-line {
    height: 1px;
    width: 0;
    opacity: 0;
    background: #ffffff;
    position: relative;
    transition: all 2s;
}

.title-line:before, 
.title-line:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ffffff;
    position: absolute;
    border-radius: 100%;
    bottom: -4px;
}
.title-line:before {
    left: -5px;
}
.title-line:after {
    right: -5px;
}

@media(max-width: 991px) {
    .header-title-container .title {
        font-size: 46px;
    }
    .header-title-container .title-line {
        width: 412px;
    }
}

@media(max-width: 575px) {
    .header-title-container .title {
        font-size: 30px
    }
    .header-title-container .title-line {
        width: 260px;
    }
    .header-title-container .title:before, 
    .title-line:before, 
    .title-line:after {
        height: 8px;
        width: 8px;
    }
}

@media(width: 320px) {
    .header-title-container .title-line {
        width: 250px;
    }
}
