.divItemContainer {
	max-width: 250px;
}
#secClientes > div > div.divFatherContainerItem.row > div{
    margin: auto;
}
.imgItemCliente{
	max-width: 100%;
    
    margin: 15px auto;
    
}
.tltItem {
	text-align: center;
    font-size: 20px;
    font-family: saira;
    border-bottom: 1px solid black;
    width: fit-content;
    margin: 0 auto;
    padding-bottom: 20px;
    margin-bottom: 20px;
}
.pItem {
    text-align: center;
    color: #333333;
    font-family: muli;
    font-size: 16px;
    line-height: 21px;
}
.divFatherContainerItem {
	justify-content: space-evenly;
    width: 90%;
    margin: 0 auto;
    padding-top:35px; 
}