.featured {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
.shade {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    background: radial-gradient(circle at 50% 50%, gray -101%, black 32%);
    opacity: 1;
    transition: all 1s;
}
.shade-loaded {
    opacity: .5;
}
.shade-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    position: absolute;
    width: 100%;
    top: 0;
}
.featured-video {
    min-height: 100%;
    min-width: 100%;
    transition: opacity 0.8s;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    background: #020003;
    background-position: center;
    background-repeat: no-repeat;
}
.featured-img {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
}
.featured-title {
    z-index: 1;
    color: #fff;
    width: 75%;
    font-family: muli;
    line-height: 1.2;
    font-size: 50px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: flicker 2500ms;
}
.featured-img-father {
    position: relative;
}
.video-ended {
    background: url('../../assets/images/footer-background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.video-ended .estruct-featured {
    background: url('../../assets/images/Reticulas-featured.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.featured-texto-corto{
    color: white;
    width: 35%;
    text-align: center;
    font-family: muli;
    font-size: 20px;
    padding-top: 20px;
    line-height: 24px;
}
.video-ended .logo-featured {
    height: 200px;
}
@keyframes flicker
{
    25% {opacity:0; color: transparent;}
    100% {opacity:1; color: white;}
}
@media(max-width: 991px){
    .featured-title {
        font-size: 45px;
    }
}
@media(max-width: 767px){
    .featured-title {
        font-size: 35px;
    }
    .featured-texto-corto {
        color: white;
        width: 50%;
        text-align: center;
        font-family: muli;
        font-size: 20px;
    }  
}
@media(max-width: 575px){
    .featured-title {
        font-size: 25px;
        width: 85%;
    }
    .featured-texto-corto {
        width: 75%;
    }
}

@keyframes pulse {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
    50% {
      -webkit-transform: translate(0, 10px);
              transform: translate(0, 10px); }
    100% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); } 
}

.hero__scroll .chevron {
    margin-top: 20px;
    display: block;
    animation: pulse 2s 1;
    color: white;
}

.chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 20px;
    position: relative;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 20px;
}

.hero__scroll {
    text-align: center;
    bottom: 0;
    position: absolute;
    width: 200px;
    margin: auto;
    display: block;
    padding-bottom: 40px;
    left: 0;
    right: 0;
    opacity: 0;
    text-transform: uppercase;
    transition: opacity 500ms;
}

.active {
    opacity: 1;
}