.tltNewsletter {
	display: flex;
	justify-content: center;
	padding-bottom: 40px;
}

.tltNewsletter h2{
	font-size: 50px;
	text-align: center;	
	font-family: saira;
}

@media (max-width:360px){
	.tltNewsletter h2 {
		font-size: 40px;
		
	}
}