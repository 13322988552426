.logo-options,
.burger-container,
.menu-social,
.menu-principal-button,
.menu-principal-link,
.portfolio-link,
.options {
  transition: all 0.5s ease, font-weight 1ms;
}
.link {
  color: white;
  filter: invert(0);
}
.link:hover {
  font-weight: bold;
  color: white;
  text-shadow: 0 0 10px white;
}
.invertToBlack {
  filter: invert(1);
}
.invertToBlack:hover {
  text-shadow: 0 0 10px black;
}
.forceWhite {
  filter: invert(0);
  color: white;
  text-shadow: 0 0 10px white;
}
.blackLineBg:before {
  background: #b9b9b9 !important;
}
.forceWhiteLineBg:before {
  background: white !important;
}
.fadeInMenu {
  opacity: 1 !important;
}
section.options {
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 3;
  padding: 35px 0;
  pointer-events: none;
  top: 0;
  opacity: 0;
}
section.options > div.container-fluid {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%;
}
.top-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-options .logo-link {
  pointer-events: auto;
}
.top-options .logo-options {
  height: 74px;
}
.top-options .logo-black {
  display: none;
}
div.burger-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 36px;
  cursor: pointer;
  pointer-events: auto;
}
div.burger-container span.burger-item {
  background: white;
  height: 5px;
  width: 57px;
  display: block;
  border-radius: 1px;
}
div.burger-container span.burger-item:nth-child(3) {
  width: calc(57px / 2);
}
.bottom-options {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.bottom-options > a {
  pointer-events: auto;
}
.bottom-options > a:hover {
  color: white;
}
.bottom-options li.menu-social-container {
  list-style: none;
  cursor: pointer;
  position: relative;
  pointer-events: auto;
}
.bottom-options li.menu-social-container ul.menu-social {
  padding: 0;
  margin: 0;
  position: absolute;
  bottom: 0px;
  opacity: 0;
  visibility: hidden;
  list-style: none;
}
.bottom-options li.menu-filter-container ul.menu-social:before {
  content: "";
  width: 1px;
  height: 140px;
  background: white;
  position: absolute;
  right: 85px;
  top: -6px;
}
.bottom-options li.menu-social-container ul.menu-social:before {
  content: "";
  width: 1px;
  height: 132px;
  background: white;
  position: absolute;
  right: 91px;
  top: 10px;
}
.black::after,
.black::before {
  background: black !important;
}

.bottom-options .menu-social-container:hover ul.menu-social {
  bottom: 25px;
  padding-bottom: 25px;
  opacity: 1;
  visibility: visible;
  font-weight: normal;
  text-shadow: none;
}
.bottom-options ul.menu-social li.menu-social-item {
  position: relative;
  height: 32px;
}
.bottom-options ul.menu-social li.filter-item {
  position: relative;
  margin: 5px;
}
.bottom-options ul.menu-social li.menu-social-item:before {
  content: "";
  width: 11px;
  height: 11px;
  position: absolute;
  border-radius: 50%;
  left: -21px;
  top: 7.5px;
}
.filter-item .menu-principal-link {
  position: relative;
  right: 100px;
  color: white;
}
.bottom-options ul.menu-social li[data-social="facebook"]:before {
  background: #405597;
}
.bottom-options ul.menu-social li[data-social="twitter"]:before {
  background: #65b3eb;
}
.bottom-options ul.menu-social li[data-social="linkedin"]:before {
  background: #5595cd;
}
.bottom-options ul.menu-social li[data-social="youtube"]:before {
  background: #dc433a;
}
.bottom-options ul.menu-social li[data-social="instagram"]:before {
  background: radial-gradient(
    circle at 5% 95%,
    #fdf497 0%,
    #fdf497 0%,
    #fd5949 42%,
    #d6249f 54%,
    #285aeb 90%
  );
}
@media (max-width: 575px) {
  section.options {
    padding: 10px 0;
  }
  .top-options .logo-options {
    height: 45px;
  }
  div.burger-container {
    height: 15px;
  }
  div.burger-container span.burger-item {
    height: 2px;
    width: 40px;
  }
}

.categoriesPortafolio {
  color: #d4d4d4;
}

.categoriesPortafolioContainer {
  opacity: 0;
  transition: all 1s;
}

.fadeInCategories {
  opacity: 1;
}

.categoriesPortafolio li{
  float: left;
  list-style:none;
  margin: 0 15px;
  font-size: 23px;
  font-family: saira;
  cursor: pointer;
}

.link.active {
  font-weight: bold;
  color: white;
  text-shadow: 0 0 10px white;
}

@media(max-width: 575px){
  .categoriesPortafolio li {
      font-size: 13px;
      margin: 0px 8px;
  }
  .descriptionContainer h3 {
      font-size: 21px;
  }
  .categoriesPortafolio {
      padding: 0;
  }
  .divClientLogo div:after{
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: gray;
      position: absolute;
      margin-left: 100px;
      display: flex;
      top: 46%;
  }
  .divClientLogo div:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: gray;
      position: absolute;
      margin-left: -100px;
      display: flex;
      top: 46%;
  }
  .divClientLogo:before {
      content: '';
      width: 60px;
      height: 2px;
      background: gray;
      opacity: 0.7;
      position: absolute;
      display: flex;
      top: 50%;
      margin-left: 70px;
  }   
  .divClientLogo:after {
      content: '';
      width: 60px;
      height: 2px;
      background: gray;
      opacity: 0.7;
      position: absolute;
      display: flex;
      top: 50%;
      margin-left: -70px;
  }    
  .arrow-right{
      margin-left: 115px;
  }
  .arrow-left {
      margin-left: -115px;
  }
}

@media(max-width: 991px) {
  .categoriesPortafolio li {
      font-size: 20px;
  }
}